import { ChiroUpAPI } from '@chiroup/client-core';
import {
  TransactionDebugParams,
  TransactionDebugResponse,
} from '@chiroup/core';

const devtoolsService = () => {
  const transactionDebug = {
    get: async (
      body: TransactionDebugParams,
    ): Promise<TransactionDebugResponse> => {
      body.fn = 'get';
      const res = await ChiroUpAPI.post('api', `/devtools/transaction-debug`, {
        body,
      });
      return res;
    },
  };

  return {
    transactionDebug,
  };
};

export default devtoolsService();
