import { lazy, Suspense, useEffect, useState } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ClinicalSkills from './components/clinical-skills/ClinicalSkills';
import Clinics from './components/clinics/Clinics';
import { addScript } from './components/common/helpers/addScript';
import Loading from './components/common/Loading';
import Dashboard from './components/dashboard/Dashboard';
import Disabilities from './components/disabilities/Disabities';
import Flags from './components/flags/Flags';
import HomePage from './components/home-page/HomePage';
import Container from './components/layout/Container';
import Marketing from './components/marketing/Marketing';
import NewUserDashboard from './components/new-user-dashboard/NewUserDashboard';
import FormsLibrary from './components/practice-resources/forms-library/FormsLibrary';
import MarketingCampaigns from './components/practice-resources/marketing/MarketingCampaigns';
import ResearchList from './components/research/ResearchList';
import StudentVerification from './components/students/StudentVerification';
import Tests from './components/tests/Tests';
import Users from './components/users/Users';
import { fetchUserAttributes, getCurrentUser } from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';
import { StopListenerCallback } from '@aws-amplify/core/dist/esm/Hub/types';
import TransactionDebug from './components/developer-tools/transaction-debug/TransactionDebugger';

// const forceLogoutTime = Number(import.meta.env.VITE_INACTIVITY_LOGOUT_TIME || 120);

const LoggedOut = lazy(() => import('./components/logged-out/LoggedOut'));

const queryClient = new QueryClient();

function App() {
  // const [inactivityModalOpen, setInactivityModalOpen] = useState(false);
  const [authState, setAuthState] = useState<string>();
  // const [isIdle, setIsIdle] = useState(false);
  const [user, setUser] = useState<object | undefined>();

  // useEffect(() => {
  //   let isDestroyed = false;
  //   let interval: NodeJS.Timeout;
  //   const doIt = async () => {
  //     interval = setInterval(async () => {
  //       const issuedAt =
  //         AuthThing?.user?.signInUserSession?.accessToken?.payload?.iat;
  //       const lastAccessTokenExpiration = dayjs(convertToMS(issuedAt));
  //       const now = dayjs();

  //       const timeSinceLastAccessToken = now.diff(
  //         lastAccessTokenExpiration,
  //         'minutes'
  //       );
  //       if (!isDestroyed && timeSinceLastAccessToken >= forceLogoutTime) {
  //         setIsIdle(true);
  //       }
  //     }, 5000);
  //   };
  //   doIt();
  //   return () => {
  //     isDestroyed = true;
  //     if (interval) {
  //       clearInterval(interval);
  //     }
  //   };
  // }, []);

  // useEffect(() => {
  //   if (authState === 'authenticated' && isIdle) {
  //     setInactivityModalOpen(true);
  //   }
  // }, [isIdle, authState]);

  useEffect(() => {
    let isDestroyed = false;
    let stopListener: StopListenerCallback;
    const doIt = async () => {
      const attr = await fetchUserAttributes().catch((err) => {
        console.error(err);
      });
      const currentUser = await getCurrentUser().catch((err) => {
        console.error(err);
      });
      if (currentUser && !isDestroyed) {
        setAuthState('authenticated');
        setUser({ ...currentUser, email: attr?.email });
      }
      stopListener = Hub.listen('auth', (data) => {
        if (!isDestroyed) {
          if (data.payload.event === 'tokenRefresh_failure') {
            setAuthState('signedOut');
            // setInactivityModalOpen(true);
          } else if (data.payload.event === 'signedOut') {
            setAuthState('signedOut');
          } else if (data.payload.event === 'signedIn') {
            setAuthState('authenticated');
            setUser(data.payload.data);
          }
        }
      });
    };
    doIt();
    return () => {
      isDestroyed = true;
      stopListener?.();
    };
  }, []);

  useEffect(() => {
    addScript(
      import.meta.env.VITE_STAGE === 'prod'
        ? 'https://js.authorize.net/v1/Accept.js'
        : 'https://jstest.authorize.net/v1/Accept.js',
    );
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        {authState === 'authenticated' && user ? (
          <Container user={user}>
            <Suspense fallback={<Loading />}>
              <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="/users/*" element={<Users />} />
                <Route path="/clinics/*" element={<Clinics />} />
                <Route path="/research" element={<ResearchList />} />
                <Route path="/tests/*" element={<Tests />} />
                <Route path="/disabilities" element={<Disabilities />} />
                <Route path="/home-page" element={<HomePage />} />
                <Route
                  path="/new-user-dashboard"
                  element={<NewUserDashboard />}
                />
                <Route
                  path="/student-verification"
                  element={<StudentVerification />}
                />
                <Route path="/flags" element={<Flags />} />
                <Route
                  path="/practice-resources/forms-library/*"
                  element={<FormsLibrary user={user} />}
                />
                <Route
                  path="/practice-resources/marketing-campaigns/*"
                  element={<MarketingCampaigns />}
                />
                <Route path="/marketing" element={<Marketing />} />
                <Route path="/clinical-skills/*" element={<ClinicalSkills />} />
                <Route
                  path="/developer-tools/transaction-debug/*"
                  element={<TransactionDebug />}
                />
                {/* <Route path="/whats-new">
                  <WhatsNew />
                </Route> */}
              </Routes>
            </Suspense>
          </Container>
        ) : (
          <Suspense fallback={<Loading />}>
            <LoggedOut />
          </Suspense>
        )}
      </BrowserRouter>
      {/* <InactivityModal
        open={inactivityModalOpen}
        setOpen={setInactivityModalOpen}
        loggedOut={authState === 'signedOut'}
        setIsIdle={setIsIdle}
      /> */}
    </QueryClientProvider>
  );
}

export default App;
