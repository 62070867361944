import {
  BeakerIcon,
  BuildingOfficeIcon,
  Cog8ToothIcon,
  DocumentMagnifyingGlassIcon,
  FlagIcon,
  HomeIcon,
  Squares2X2Icon,
  UserIcon,
} from '@heroicons/react/24/outline';
import { MenuItem } from './MenuItem.interface';

export const menuItems: MenuItem[] = [
  {
    path: '',
    title: 'Dashboard',
    icon: (
      <path d="M3 4a1 1 0 011-1h12a1 1 0 011 1v2a1 1 0 01-1 1H4a1 1 0 01-1-1V4zM3 10a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H4a1 1 0 01-1-1v-6zM14 9a1 1 0 00-1 1v6a1 1 0 001 1h2a1 1 0 001-1v-6a1 1 0 00-1-1h-2z" />
    ),
  },
  {
    path: 'users',
    title: 'Users',
    icon: <UserIcon className="h-6 w-6" />,
  },
  {
    path: 'clinics',
    title: 'Clinics',
    icon: <BuildingOfficeIcon className="h-6 w-6" />,
  },
  {
    path: 'research',
    title: 'Research',
    icon: <DocumentMagnifyingGlassIcon className="h-6 w-6" />,
  },
  {
    path: 'disabilities',
    title: 'Disabilities',
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M20.618 5.984A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016zM12 9v2m0 4h.01"
        />
      </svg>
    ),
  },
  {
    path: 'tests',
    title: 'Tests',
    icon: <BeakerIcon className="h-6 w-6" />,
  },
  {
    path: 'home-page',
    title: 'Home Page',
    icon: <HomeIcon className="h-6 w-6" />,
  },
  {
    path: 'new-user-dashboard',
    title: 'New user dashboard',
    icon: <Squares2X2Icon className="h-6 w-6" />,
  },
  {
    path: 'student-verification',
    title: 'Student verification',
    icon: <UserIcon className="h-6 w-6" />,
  },
  {
    path: 'flags',
    title: 'Feature flags',
    icon: <FlagIcon className="h-6 w-6" />,
  },
  {
    path: 'marketing',
    title: 'Marketing',
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="w-6 h-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M6.633 10.5c.806 0 1.533-.446 2.031-1.08a9.041 9.041 0 012.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 00.322-1.672V3a.75.75 0 01.75-.75A2.25 2.25 0 0116.5 4.5c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 01-2.649 7.521c-.388.482-.987.729-1.605.729H13.48c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 00-1.423-.23H5.904M14.25 9h2.25M5.904 18.75c.083.205.173.405.27.602.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 01-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 10.203 4.167 9.75 5 9.75h1.053c.472 0 .745.556.5.96a8.958 8.958 0 00-1.302 4.665c0 1.194.232 2.333.654 3.375z"
        />
      </svg>
    ),
  },
  {
    path: 'clinical-skills',
    title: 'Clinical skills',
    icon: (
      <path d="M9 4.804A7.968 7.968 0 005.5 4c-1.255 0-2.443.29-3.5.804v10A7.969 7.969 0 015.5 14c1.669 0 3.218.51 4.5 1.385A7.962 7.962 0 0114.5 14c1.255 0 2.443.29 3.5.804v-10A7.968 7.968 0 0014.5 4c-1.255 0-2.443.29-3.5.804V12a1 1 0 11-2 0V4.804z" />
    ),
    menuItems: [
      {
        path: 'conditions',
        title: 'Condition reference',
      },
      {
        path: 'evaluations',
        title: 'Clinical evaluations',
      },
      {
        path: 'treatments',
        title: 'Treatment techniques',
      },
      {
        path: 'exercises',
        title: 'Exercises',
      },
      {
        path: 'modality',
        title: 'Modalities',
      },
      {
        path: 'advice',
        title: 'Advice',
      },
      {
        path: 'supplements',
        title: 'Supplements and Supplies',
      },
      {
        path: 'common-treatments',
        title: 'My treatment desc.',
      },
    ],
  },
  {
    path: 'practice-resources',
    title: 'Practice resources',
    icon: <BuildingOfficeIcon className="h-6 w-6" />,
    menuItems: [
      {
        path: 'marketing-campaigns',
        title: 'Marketing campaigns',
      },
      {
        path: 'forms-library/business',
        title: 'Forms library',
      },
    ],
  },
  {
    path: 'developer-tools',
    title: 'Developer tools',
    icon: <Cog8ToothIcon className="h-6 w-6" />,
    menuItems: [
      {
        path: 'transaction-debug',
        title: 'Transaction debug',
      },
    ],
  },
  // {
  //   path: 'whats-new',
  //   title: "What's new",
  //   icon: (
  //     <svg
  //       xmlns="http://www.w3.org/2000/svg"
  //       className="h-6 w-6"
  //       fill="none"
  //       viewBox="0 0 24 24"
  //       stroke="currentColor"
  //       strokeWidth={2}
  //     >
  //       <path
  //         strokeLinecap="round"
  //         strokeLinejoin="round"
  //         d="M19 20H5a2 2 0 01-2-2V6a2 2 0 012-2h10a2 2 0 012 2v1m2 13a2 2 0 01-2-2V7m2 13a2 2 0 002-2V9a2 2 0 00-2-2h-2m-4-3H9M7 16h6M7 8h6v4H7V8z"
  //       />
  //     </svg>
  //   ),
  // },
];
